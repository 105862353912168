import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

class TabsTwo extends Component {
  render() {
    let tab1 = "Main skills",
      tab2 = "Awards",
      tab3 = "Experience",
      tab4 = "Education & Certification";
    const { tabStyle } = this.props;
    return (
      <div>
        {/* Start Tabs Area */}
        <div className="tabs-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Tabs>
                  <TabList className={`${tabStyle}`}>
                    <Tab>{tab1}</Tab>
                    
                    <Tab>{tab3}</Tab>
                    <Tab>{tab4}</Tab>
                  </TabList>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <a>
                            Full stack development <span> - Common Languages </span>
                          </a>
                          Angular, React, NodeJs, MongoDB, HTML, CSS, and JavaScript
                        </li>
                        <li>
                          <a>
                            Graphical Design <span> - Editing</span>
                          </a>
                          Adobe Photoshop, Adobe Stock, Adobe Illustrator
                        </li>
                        <li>
                          <a>
                            Familiar with team softwares <span> Organization </span>
                          </a>
                          Azure,
                          Jira,
                          GitHub,
                          Slack,
                        </li>
                      </ul>
                    </div>
                  </TabPanel>

                

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <a>
                            Full Stack Developer<span> - W2T</span>
                          </a>{" "}
                          January, 2023 - Current
                        </li>
                      </ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <a>
                            High School Diploma 
                            <span> - Lees Summit West High School, MO</span>
                          </a>{" "}
                          2022
                        </li>
                        <li>
                          <a>
                            Ongoing Education 
                            <span> - Metropoltan Community College of Kansas City, MO</span>
                          </a>{" "}
                          Gradution expected in 2027
                        </li>
                      </ul>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
        {/* End Tabs Area */}
      </div>
    );
  }
}

export default TabsTwo;
